import imgTrybetunes from '../assets/img-sobrancelhas.webp';
import imgTrybeWallet from '../assets/img-depilacao.webp';
import imgNatours from '../assets/img-pedicure.webp';
import imgDragNdrop from '../assets/img-manicure.webp';


const allProjects = [
  {
    id: 12,
    name: 'MANICURE',
    moduleCourse: 'Front-end',
    img: imgDragNdrop,
    technologies: 'Sass, React, JSX, Dropzone, HTML5',
    text: 'Sou uma manicure dedicada, apaixonada por cuidar e embelezar as unhas dos meus clientes. Minha missão é proporcionar uma experiência relaxante e satisfatória, onde cada detalhe é cuidadosamente tratado. Com anos de experiência na área, desenvolvi habilidades que vão além do simples cuidado com as unhas.'
  },

 {
    id: 11,
    name: 'PEDICURE',
    moduleCourse: 'Front-end',
    img: imgNatours,
    technologies: 'Sass, React, JSX, CSS3, HTML5',
    text: 'O cuidado com a higiene é uma prioridade em meu trabalho. Sigo rigorosos protocolos de esterilização de instrumentos e preparo um ambiente limpo e seguro para cada atendimento. Acredito que cada detalhe faz a diferença, desde o acolhimento caloroso até o atendimento personalizado, respeitando as necessidades e preferências individuais de cada cliente.'
  },
  
  
  {
    id: 9,
    name: 'DEPILAÇÂO',
    moduleCourse: 'Front-end',
    img: imgTrybeWallet,
    technologies: 'Redux, React, JSX, CSS3, HTML5',
    text: 'Sou uma depiladora dedicada e apaixonada por proporcionar uma experiência de cuidado e bem-estar aos meus clientes. Com anos de experiência na área, desenvolvi técnicas precisas e eficientes para garantir resultados impecáveis, sempre priorizando o conforto e a satisfação de quem confia no meu trabalho.'
  },
  
  {
    id: 8,
    name: 'SOBRANCELHAS',
    moduleCourse: 'Front-end',
    img: imgTrybetunes,
    technologies: 'React, JSX, CSS3, HTML5',
    text: 'Sou uma designer de sobrancelhas apaixonada pelo que faço, dedicada a realçar a beleza natural de cada cliente através de técnicas precisas e personalizadas. Com um olhar atento aos detalhes e uma compreensão profunda das características faciais, trabalho para criar sobrancelhas que harmonizam perfeitamente com o rosto, destacando sua expressão e personalidade.'
  },

  
];


export default allProjects;
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialMedia from '../components/SocialMedia';
// import Logo from '../assets/logo.png'; // Importe sua imagem de logo

import '../styles/pages/home.scss';

export default class Home extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <div className='all-home'>
        <Header />
        {/* <div className="logo-container">
              <img src={Logo} alt="Logo" className="logo" />
        </div> */}
          <div className="home">

            <section className="home-section home-text-animation">
              <p className="home-name-1">Femmenyce</p>
              <p className="home-name-2">Nails</p>
              <div className="home-subtitle">
                <p className="home-subtitle-1">Manicure & Pedicure</p><span>|</span>
                <p className="home-subtitle-2">Emaltação em Gel</p><span>|</span>
                <p className="home-subtitle-3">Depilação</p><span>|</span>
                <p className="home-subtitle-4">Designer de Sombrancelha</p>
              </div>
            </section>
            <SocialMedia />
          </div>
          <Footer />
      </div>
    )
  }
}

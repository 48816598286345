import React from 'react';
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom";
import '../styles/components/socialMedia.scss';

export default function SocialMedia() {
  return (
    <section className="social-media">
      <NavLink exact to="/about" activeClassName="selected">
        <Icon icon="bi:calendar" />
      </NavLink >
      <a href="https://facebook.com/femmenyce" target="_blank" rel="noopener noreferrer">
        <Icon icon="bi:facebook" />
      </a>
      <a href="https://www.instagram.com/femmenyce/" target="_blank" rel="noopener noreferrer">
        <Icon icon="bi:instagram" />
      </a>

      <a href="https://wa.me/554120182058" target="_blank" rel="noopener noreferrer" className="mail-icon">
        <Icon icon="bi:whatsapp" />
      </a>
    </section>
  )
}

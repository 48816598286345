import React, { Component } from 'react';
import Header from '../components/Header';
import ListProjects from './ListProjects';
import SocialMedia from '../components/SocialMedia';

import '../styles/pages/projects.scss';

export default class Projects extends Component {
  render() {
    return (
      <div className="dark-background">
        <div className="header-social-media">
          <Header />
          <SocialMedia />
        </div>
        
        <div className="project">

        <section className='project-thumbnail-section'>
          <ListProjects />
        </section>
        
      </div>
      </div>
      
    )
  }
}
